import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import { ParallaxProvider } from 'react-scroll-parallax';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'normalize.css';
import 'main.css/dist/_base.css';
import GlobalStyles from './styles/GlobalStyles';
import Typography from './styles/Typography';

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

const container = document.getElementById('app');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

const articleStructuredData = {
  __html: JSON.stringify({
    '@context': 'http://schema.org',
    '@type': 'Person',
    name: 'Timoline',
    url: 'https://www.timoline.com',
    jobTitle: 'Timoline - Digital Photobook',
    description:
      'Build timelines with your friends and family so that you can easy share, find and remember important moments in your life. Be it weddings, childhood, career, travelling, or more, Timoline helps you to create a digital photobook so that you can look back on your experiences fondly.',
    image: 'https://timoline.com/static/media/logo.79af9b5e.svg',
    memberOf: {
      '@type': 'Organisation',
      name: 'Timoline',
      url: 'https://www.timoline.com',
    },
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': 'https://www.timoline.com',
    },
  }),
};

root.render(
  <React.StrictMode>
    <script type="application/ld+json">
      {JSON.stringify(articleStructuredData)}
    </script>
    <ParallaxProvider>
      <GlobalStyles />
      <Typography />
      <App />
    </ParallaxProvider>
  </React.StrictMode>,
);
