import React from 'react';
import styled from 'styled-components';
import { useTheme } from '../context/theme';
import { midnightBlue } from '../styles/colors';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  min-height: 100vh;
  background-color: ${({ theme }) =>
    theme === 'dark' ? midnightBlue : '#FFFFFF'};
  overflow: hidden;
`;

function Wrapper({ children }) {
  const { theme } = useTheme();

  return <StyledWrapper theme={theme}>{children}</StyledWrapper>;
}

export default Wrapper;
