export const blue = '#4962C9';
export const blueDark = '#3D4772';
export const yellow = '#DBC772';
export const greyDark = '#85899D';
export const greyMedium = '#CACFE6';
export const greyLight = '#ECEEF8';
export const red = '#D8724C';
export const green = '#73A85B';
export const midnightBlue = '#2E2F38';
export const midnightBlueLight = '#474855';
export const midnightBlueMedium = '#282B3B';
export const white = '#F5F5F5';
