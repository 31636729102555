import { initializeApp } from 'firebase/app';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: 'timoline-94b35.firebaseapp.com',
  projectId: 'timoline-94b35',
  storageBucket: 'timoline-94b35.appspot.com',
  messagingSenderId: '887249202847',
  appId: '1:887249202847:web:d927b4ad21d25ec1a0bcaa',
};

// const app = getApp();
// const apps = getApps();

export default initializeApp(config);
