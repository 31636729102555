import React from 'react';

import { ThemeProvider } from './theme';
import { ToastProvider } from './toast';

function AppProviders({ children }) {
  return (
    <ThemeProvider>
      <ToastProvider>{children}</ToastProvider>
    </ThemeProvider>
  );
}

export default AppProviders;
