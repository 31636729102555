import React, { Suspense, lazy, useLayoutEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useParallaxController } from 'react-scroll-parallax';
import AppProviders from './context';
import Wrapper from './components/Wrapper';
import Toast from './components/Toast';
import './firebase';

const Terms = lazy(() => import('./pages/Terms'));
const Privacy = lazy(() => import('./pages/Privacy'));
const Home = lazy(() => import('./pages/Home'));
const Help = lazy(() => import('./pages/Help'));

function App() {
  const parallaxController = useParallaxController();

  useLayoutEffect(() => {
    const handler = () => parallaxController.update();

    window.addEventListener('load', handler);

    return () => window.removeEventListener('load', handler);
  }, [parallaxController]);

  return (
    <AppProviders>
      <Wrapper>
        <Router>
          <Suspense fallback={<div />}>
            <Switch>
              <Route path="/terms">
                <Terms />
              </Route>
              <Route path="/privacy">
                <Privacy />
              </Route>
              <Route path="/help">
                <Help />
              </Route>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          </Suspense>
        </Router>
      </Wrapper>
      <Toast />
    </AppProviders>
  );
}

export default App;
